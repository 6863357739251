import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getStorageMediaUrl(url: string | undefined | null): string {
  if (!url) return "";
  return process.env.REACT_APP_STORAGE_URL + url;
}
