import type React from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import type { Blog } from "./types/blog";
import { getBlogBySlugApi } from "./apis/blog";
import { Helmet } from "react-helmet-async";
import { formatDate } from "./utils/dateUtils";
import { getStorageMediaUrl } from "./lib/utils";

const BlogDetailPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();
  const currentLang = i18n.language;

  const { data, isLoading, isError } = useQuery<{ data: Blog }>({
    queryKey: ["blog", slug],
    queryFn: () => getBlogBySlugApi(slug as string),
    refetchOnMount: false,
    retry: 1,
  });

  const blog = data?.data;

  if (isLoading) {
    return (
      <div className="max-w-3xl mx-auto px-4 py-12">
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-gray-200 rounded w-3/4"></div>
          <div className="h-4 bg-gray-200 rounded w-1/2"></div>
          <div className="h-4 bg-gray-200 rounded w-5/6"></div>
          <div className="h-64 bg-gray-200 rounded"></div>
        </div>
      </div>
    );
  }

  if (isError || !blog) {
    return (
      <div className="max-w-3xl mx-auto px-4 py-12 text-center">
        <h1 className="text-3xl font-bold text-secondary mb-4">
          {t("blog.notFound")}
        </h1>
        <p className="text-gray-dark mb-6">{t("blog.articleNotFound")}</p>
        <Link
          to="/blogs"
          className="bg-secondary text-white px-6 py-2 rounded-full hover:bg-accent transition-colors duration-200"
        >
          {t("blog.backToList")}
        </Link>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        {/* Basic SEO */}
        <title>{blog.title}</title>
        <meta name="description" content={blog.excerpt} />
        <link
          rel="canonical"
          href={`https://efawtara.com/blogs/${blog.slug}`}
        />

        {/* Open Graph */}
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={blog.excerpt} />
        <meta
          property="og:image"
          content={getStorageMediaUrl(blog.cover_image)}
        />
        <meta
          property="og:url"
          content={`https://efawtara.com/blogs/${blog.slug}`}
        />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Your Blog Name" />
        <meta
          property="og:locale"
          content={i18n.language === "ar" ? "ar_AR" : "en_US"}
        />

        {/* Article Tags */}
        <meta
          property="article:published_time"
          content={blog.published_at || blog.created_at}
        />
        <meta property="article:section" content={blog.category.name} />
        <meta property="article:author" content={blog.user.name} />

        {/* Twitter Cards */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={blog.title} />
        <meta name="twitter:description" content={blog.excerpt} />
        <meta
          name="twitter:image"
          content={getStorageMediaUrl(blog?.cover_image)}
        />
      </Helmet>

      <article className="max-w-3xl mx-auto px-4 py-12">
        <header className="mb-8">
          <Link
            to="/blogs"
            className="text-gray-dark hover:text-secondary transition-colors duration-200"
          >
            ← {t("blog.backToList")}
          </Link>
          <h1 className="text-4xl md:text-5xl font-bold mt-6 mb-4">
            {blog.title}
          </h1>
          <div className="flex items-center text-gray-600">
            <div>
              <p className="font-medium">{blog.user.name}</p>
              <p className="text-sm">
                {formatDate(blog.published_at || blog.created_at, currentLang)}{" "}
                · {t("blog.minuteRead", { count: blog.reading_time })}
              </p>
            </div>
          </div>
        </header>

        {blog.cover_image && (
          <img
            src={getStorageMediaUrl(blog.cover_image) || "/placeholder.svg"}
            alt={blog.title}
            className="w-full h-auto object-cover rounded-lg mb-8"
          />
        )}

        <div
          className="prose prose-lg max-w-none"
          dangerouslySetInnerHTML={{ __html: blog.content }}
        />

        <footer className="mt-12 pt-6 border-t border-gray-200">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <span className="bg-gray-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded-full text-sm transition-colors duration-200">
                {blog.category.name}
              </span>
              <span className="text-gray-500">
                {t("blog.viewCount", { count: blog.view_count })}
              </span>
            </div>
          </div>
        </footer>
      </article>
    </>
  );
};

export default BlogDetailPage;
