import axiosInstance from "./axios";

const getAllBlogsApi = async ({ search = "", page = 1 } = {}) => {
  try {
    const response = await axiosInstance.get("/blogs", {
      params: {
        search: search || undefined, // Omit if empty
        page,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching blogs:", error);
    throw error;
  }
};

const getBlogBySlugApi = async (slug: string) =>
  await axiosInstance.get(`/blogs/${slug}`);

export { getAllBlogsApi, getBlogBySlugApi };
