import type React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import type { Blog } from "../types/blog";
import { calculateReadTime, formatDate } from "../utils/dateUtils";
import { getStorageMediaUrl } from "../lib/utils";

interface BlogCardProps {
  blog: Blog;
}

const BlogCard: React.FC<BlogCardProps> = ({ blog }) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  return (
    <div className="bg-white rounded-lg overflow-hidden hover:shadow-lg transition-shadow duration-300 border">
      {blog.cover_image && (
        <div className="relative h-48 overflow-hidden">
          <img
            src={getStorageMediaUrl(blog.cover_image) || "/placeholder.svg"}
            alt={blog.title}
            className="w-full h-full object-cover transition-transform duration-500 hover:scale-105"
          />
          <div className="absolute bottom-0 left-0 bg-primary text-dark px-3 py-1 text-sm font-medium">
            {blog.category.name}
          </div>
        </div>
      )}

      <div className="p-5">
        <div className="flex items-center mb-3 text-sm text-gray-dark">
          <span>
            {t("blog.publishedOn")}{" "}
            {formatDate(blog.published_at || blog.created_at, currentLang)}
          </span>
          <span className="mx-2">•</span>
          <span>
            {t("blog.minuteRead", { count: calculateReadTime(blog.content) })}
          </span>
        </div>

        <h3 className="text-xl font-bold mb-2 text-dark">
          <Link
            to={`${blog.slug}`}
            className="hover:text-accent transition-colors duration-200"
          >
            {blog.title}
          </Link>
        </h3>

        <p className="text-gray-dark mb-4 line-clamp-3">{blog.excerpt}</p>

        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <span className="text-sm text-gray-dark">
              {t("blog.by")} {blog.user.name}
            </span>
          </div>

          <div className="text-sm text-gray-dark">
            {t("blog.viewCount", { count: blog.view_count })}
          </div>
        </div>

        <Link
          to={`${blog.slug}`}
          className="mt-4 inline-block text-accent hover:text-secondary font-medium transition-colors duration-200"
        >
          {t("blog.readMore")} →
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;
