"use client";

import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import BlogCard from "./BlogCard";
import { getAllBlogsApi } from "../apis/blog";
import type { Blog } from "../types/blog";

interface PaginatedResponse {
  current_page: number;
  data: Blog[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: Array<{
    url: string | null;
    label: string;
    active: boolean;
  }>;
  next_page_url: string | null;
  path: string;
  per_page: number;
  prev_page_url: string | null;
  to: number;
  total: number;
}

const BlogList: React.FC = () => {
  const { t } = useTranslation();
  const { category } = useParams<{ category?: string }>();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(""); // Added search state

  const { data, isLoading, isError, error } = useQuery<PaginatedResponse>({
    queryKey: ["blogs", category, page, search], // Include search in queryKey
    queryFn: () => getAllBlogsApi({ search, page }),
  });

  const blogs: Blog[] = data?.data || []; // Adjusted to match response structure
  const pagination = data; // Adjusted to match response structure

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {[1, 2, 3, 4].map((i) => (
            <div
              key={i}
              className="bg-gray-light animate-pulse h-96 rounded-lg"
            ></div>
          ))}
        </div>
      );
    }

    if (isError) {
      return (
        <div className="text-center py-12">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-16 w-16 mx-auto text-red-500 mb-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          <h3 className="text-xl font-bold text-dark mb-2">
            {t("blog.error")}
          </h3>
          <p className="text-gray-dark">
            {error instanceof Error
              ? error.message
              : "An error occurred while fetching blogs."}
          </p>
        </div>
      );
    }

    if (blogs.length === 0) {
      return (
        <div className="text-center py-12 flex flex-col items-center justify-center w-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-16 w-16 mx-auto text-gray-dark mb-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <h3 className="text-xl font-bold text-dark mb-2">
            {t("blog.noResults")}
          </h3>
          <p className="text-gray-dark mb-4">
            {category
              ? t("blog.noCategoryResults", { category })
              : t("blog.noArticlesFound")}
          </p>
          {category && (
            <Link
              to="/blogs"
              className="text-secondary hover:text-accent transition-colors duration-200"
            >
              {t("blog.viewAllArticles")}
            </Link>
          )}
        </div>
      );
    }

    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {blogs.map((blog) => (
          <BlogCard key={blog.id} blog={blog} />
        ))}
      </div>
    );
  };

  return (
    <div className="w-full lg:max-w-landing mx-auto px-4 py-12">
      {/* Title and Description Section */}
      <div className="text-center mb-12">
        <h1 className="text-4xl md:text-5xl font-bold text-secondary mb-4">
          {t("blog.title")}
        </h1>
        <p className="text-lg text-gray-dark max-w-2xl mx-auto">
          {category
            ? t("blog.categoryDescription", { category })
            : t("blog.description")}
        </p>
      </div>

      {/* Search Input */}
      <div className="mb-8 w-full flex justify-center items-center">
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={t("blog.searchPlaceholder") || "Search blogs..."}
          className="w-full max-w-md mx-auto p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-secondary"
        />
      </div>

      <div className="flex flex-col lg:flex-row gap-8">
        {/* Main content */}
        <div className="w-full">{renderContent()}</div>
      </div>

      {/* Pagination Controls */}
      {pagination && blogs.length > 0 && (
        <div className="mt-8 flex justify-center gap-4">
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={pagination.current_page === 1}
            className="px-4 py-2 bg-secondary text-white rounded-lg disabled:bg-gray-300"
          >
            {t("blog.previous") || "Previous"}
          </button>
          <span className="self-center">
            {t("blog.page", {
              current: pagination.current_page,
              total: pagination.last_page,
            })}
          </span>
          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={pagination.current_page === pagination.last_page}
            className="px-4 py-2 bg-secondary text-white rounded-lg disabled:bg-gray-300"
          >
            {t("blog.next") || "Next"}
          </button>
        </div>
      )}
    </div>
  );
};

export default BlogList;
