import type React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import BlogList from "./components/BlogList";
import i18n from "./messages";

const BlogsPage: React.FC = () => {
  const { t } = useTranslation();

  document.body.dir = i18n.dir();

  return (
    <>
      <Helmet>
        <title>{t("blog.title")}</title>
        <meta
          name="description"
          content="Read our latest articles and stay updated with the newest trends."
        />
      </Helmet>

      <BlogList />
    </>
  );
};

export default BlogsPage;
