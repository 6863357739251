import { format, parseISO } from "date-fns";
import { enUS, fr, arSA, type Locale } from "date-fns/locale";

export const formatDate = (dateString: string, language = "en"): string => {
  const date = parseISO(dateString);

  const locales: Record<string, Locale> = {
    en: enUS,
    fr: fr,
    ar: arSA,
  };

  const locale = locales[language] || enUS;

  return format(date, "PPP", { locale });
};

export const calculateReadTime = (content: string): number => {
  const wordsPerMinute = 200;
  const wordCount = content.split(/\s+/).length;
  return Math.ceil(wordCount / wordsPerMinute);
};
