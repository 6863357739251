import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./components/header";
import FooterSection from "./components/footer";

const Layout: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow mt-20">
        <Outlet />
      </main>
      <div className="bg-secondary w-full mt-auto">
        <FooterSection />
      </div>
    </div>
  );
};

export default Layout;
